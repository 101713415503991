import { createApp } from 'vue/dist/vue.esm-bundler.js';
import App from './exburst-main.vue'; // Adjust the path as per your project structure
import '../tailwind.css'; // Ensure these CSS files are correctly located
import router from '../router'; // Ensure this path points to your router configuration
import VueGtag from 'vue-gtag'

const app = createApp(App);

app.use(
  VueGtag,
  {
    appName: 'ExBurst.dev Home',
    pageTrackerScreenviewEnabled: true,
    config: {id: "G-13BZTMMGC0"}
  }
)

app.use(router); // Use Vue Router
app.mount('#app'); // Mount the app to the DOM element with id 'app'
