import { defineStore } from 'pinia';
import { useCardlistStore } from './cardlistStore';
import { useGlobalStore } from './globalStore';

export const useDeckbuilderStore = defineStore('deckbuilderStore', {
  state: () => ({
    currentDecklist: [],
    leaderCard: null,
    totalCardsInDeck: 0,
    closeDisplayList: false,
    hasLeaderCardInDeck: false,
    isOwnedByUser: false,
    orderBy: null,
    sortDirection: null,
    currentDecklistSeries: [],
    storedDecklist: null,
    storedSeries: null,
    deckId: null,
    favoriteCard: null,
    deckOwnerId: null,
    archetype: null,
    deckContent: null,
    deckOwner: null,
    notes: null,
    isPublic: true,
    selectedItems: '',
    deckName: '',
    currentlyViewedCardId: null,
    selectedCardId: null,
    deckMode: null,
    customSeries: [],
  }),
  actions: {
    updateTotalCardsInDeck() {
      const cardlistStore = useCardlistStore();
      if (!cardlistStore.gameConfig) return;
      const deckSeparatorsColumn = cardlistStore.gameConfig.deckSeparators_column;
      const deckSeparators = cardlistStore.gameConfig.deckSeparators;
      const ignoredSeparators = cardlistStore.gameConfig.ignoredSeparators || [];
      const leaderCard = this.leaderCard;

      const decklistDetails = this.getDecklistDetails();

      if (deckSeparators.length === 0) {
        this.totalCardsInDeck = decklistDetails.reduce((total, card) => {
          const isIgnoredSeparator = ignoredSeparators.includes(card[deckSeparatorsColumn]);
          const isLeaderCard = leaderCard && card === leaderCard;
          return !isIgnoredSeparator && !isLeaderCard ? total + card.count : total;
        }, 0);
        this.updateTraits();
        return;
      }

      const filteredDeck = decklistDetails.filter(card => {
        const cardSeparatorType = card[deckSeparatorsColumn];
        const isIgnoredSeparator = ignoredSeparators.includes(cardSeparatorType);
        const isLeaderCard = leaderCard && card === leaderCard;
        return deckSeparators.includes(cardSeparatorType) && !isIgnoredSeparator && !isLeaderCard;
      });

      this.totalCardsInDeck = filteredDeck.reduce((total, card) => total + card.count, 0);
      this.updateTraits();
    },
    addCard(cardNo, count = 1) {
      const cardlistStore = useCardlistStore();
      const globalStore = useGlobalStore();
      if (!cardlistStore.gameConfig) return;
      const cardNoColumn = cardlistStore.gameConfig.cardId_column;
      try {
        const existingCard = this.currentDecklist.find(card => card[cardNoColumn] === cardNo);
        if (existingCard) {
          existingCard.count += count;
        } else {
          this.currentDecklist.push({ [cardNoColumn]: cardNo, count });
        }
        // Set the favorite card if not already set
        if (!this.favoriteCard) {
          this.favoriteCard = cardNo;
        }
        this.updateTotalCardsInDeck();
        this.updateTraits();
        this.getArchetypes(); // Update archetypes when a card is added
        this.saveDecklistToLocalStorage();
      } catch (error) {
        globalStore.addToast({
          message: `Error adding card: ${error.message}`,
          type: 'error',
        });
      }
    },
    addAllVisibleCards() {
      const cardlistStore = useCardlistStore();
      const globalStore = useGlobalStore();
      if (!cardlistStore.gameConfig) return;
      try {
        cardlistStore.filteredCards.forEach(card => {
          if (card.isAd !== true) {
            this.addCard(card[cardlistStore.gameConfig.cardId_column]);
          }
        });
      } catch (error) {
        globalStore.addToast({
          message: `Error adding all visible cards: ${error.message}`,
          type: 'error',
        });
      }
    },
    removeCard(cardNo, count = 1) {
      const cardlistStore = useCardlistStore();
      const globalStore = useGlobalStore();
      if (!cardlistStore.gameConfig) return;
      const cardNoColumn = cardlistStore.gameConfig.cardId_column;
      try {
        const existingCard = this.currentDecklist.find(card => card[cardNoColumn] === cardNo);
        if (existingCard) {
          existingCard.count -= count;
          if (existingCard.count <= 0) {
            this.currentDecklist = this.currentDecklist.filter(card => card[cardNoColumn] !== cardNo);
          }
        }
        this.updateTotalCardsInDeck();
        this.updateTraits();
        this.getArchetypes();
        this.saveDecklistToLocalStorage();
      } catch (error) {
        globalStore.addToast({
          message: `Error removing card: ${error.message}`,
          type: 'error',
        });
      }
    },
    setCardCount(cardNo, count) {
      const cardlistStore = useCardlistStore();
      const globalStore = useGlobalStore();
      if (!cardlistStore.gameConfig) return;
      if (count < 0) return;
      const cardNoColumn = cardlistStore.gameConfig.cardId_column;
      try {
        const existingCard = this.currentDecklist.find(card => card[cardNoColumn] === cardNo);
        if (existingCard) {
          existingCard.count = count;
          if (existingCard.count === 0) {
            this.currentDecklist = this.currentDecklist.filter(card => card[cardNoColumn] !== cardNo);
          }
        } else if (count > 0) {
          this.currentDecklist.push({ [cardNoColumn]: cardNo, count });
        }
        this.updateTotalCardsInDeck();
        this.updateTraits();
        this.getArchetypes();
        this.saveDecklistToLocalStorage();
      } catch (error) {
        globalStore.addToast({
          message: `Error setting card count: ${error.message}`,
          type: 'error',
        });
      }
    },
    setMaxCardCount(cardNo) {
      const cardlistStore = useCardlistStore();
      const globalStore = useGlobalStore();
      if (!cardlistStore.gameConfig) return;
      const maxCount = cardlistStore.gameConfig.maximumUniqueCardPerDeck;
      try {
        this.setCardCount(cardNo, maxCount);
      } catch (error) {
        globalStore.addToast({
          message: `Error setting max card count: ${error.message}`,
          type: 'error',
        });
      }
    },
    getDecklistDetails() {
      const cardlistStore = useCardlistStore();
      if (!cardlistStore.gameConfig) return [];
      if (!cardlistStore.init) return [];
      const cardIdColumn = cardlistStore.gameConfig.cardId_column;
      const details = this.currentDecklist.map(deckCard => {
        const cardDetails = cardlistStore.cards.find(card => card[cardIdColumn] === deckCard[cardIdColumn]);
        return {
          ...cardDetails,
          [cardIdColumn]: deckCard[cardIdColumn],
          count: deckCard.count
        };
      });

      // Update currentDecklistSeries based on series present in the decklist
      this.currentDecklistSeries = [...new Set(details.map(card => card[cardlistStore.gameConfig.series_column]))];

      return details;
    },
    exportDecklistAsText() {
      const cardlistStore = useCardlistStore();
      if (!cardlistStore.gameConfig) return '';
      const decklistEntries = this.currentDecklist.map(card => ({
        cardNo: card[useCardlistStore().gameConfig.cardId_column],
        count: card.count
      }));

      const sortedDecklist = decklistEntries.sort((a, b) => {
        if (a.cardNo.includes("-AP") && !b.cardNo.includes("-AP")) {
          return 1;
        }
        if (!a.cardNo.includes("-AP") && b.cardNo.includes("-AP")) {
          return -1;
        }
        return 0;
      });

      const decklistText = this.generateDecklistText(sortedDecklist);
      return decklistText;
    },
    exportDecklistAsLimitless() {
      const cardlistStore = useCardlistStore();
      if (!cardlistStore.gameConfig) return '';
      const decklistDetails = this.getDecklistDetails();

      const cardCounts = {
        pokemon: {},
        trainer: {}
      };

      decklistDetails.forEach(card => {
        const { count, cardName, cardNo } = card;
        let setCode, cardNumber;

        if (cardNo.startsWith("P-A")) {
          setCode = "P-A";
          cardNumber = cardNo.slice(3);
        } else {
          [setCode, cardNumber] = cardNo.split("-");
        }

        cardNumber = cardNumber.replace(/^0+/, '');

        const key = `${cardName} ${setCode} ${cardNumber}`;

        if (card.color === "Item" || card.color === "Supporter") {
          cardCounts.trainer[key] = (cardCounts.trainer[key] || 0) + count;
        } else {
          cardCounts.pokemon[key] = (cardCounts.pokemon[key] || 0) + count;
        }
      });

      const pokemonCards = Object.entries(cardCounts.pokemon).map(([key, totalCount]) => {
        return `${totalCount} ${key}`;
      });
      const trainerCards = Object.entries(cardCounts.trainer).map(([key, totalCount]) => {
        return `${totalCount} ${key}`;
      });

      const totalPokemonCount = Object.values(cardCounts.pokemon).reduce((sum, count) => sum + count, 0);
      const totalTrainerCount = Object.values(cardCounts.trainer).reduce((sum, count) => sum + count, 0);

      const pokemonSection = `Pokémon: ${totalPokemonCount}\n${pokemonCards.join("\n")}`;
      const trainerSection = `Trainer: ${totalTrainerCount}\n${trainerCards.join("\n")}`;

      const decklistText = `${pokemonSection}\n\n${trainerSection}`;

      return decklistText;
    },
    exportDecklistAsTextFile() {
      try {
        const decklistText = this.exportDecklistAsText();
        const blob = new Blob([decklistText], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'decklist.txt';
        link.click();
        URL.revokeObjectURL(url);
      } catch (error) {
        const globalStore = useGlobalStore();
        globalStore.addToast({
          message: `Error exporting decklist as text file: ${error.message}`,
          type: 'error',
        });
      }
    },
    closeDisplayList(value) {
      this.closeDisplayList = value;
    },
    setHasLeaderCardInDeck(hasLeader) {
      this.hasLeaderCardInDeck = hasLeader;
    },
    generateDecklistText(decklist) {
      let decklistText = '';
      decklist.forEach((card) => {
        decklistText += `${card.count} x ${card.cardNo}\n`;
      });
      return decklistText;
    },
    clearDecklist() {
      this.currentDecklist = [];
      this.leaderCard = null;
      this.totalCardsInDeck = 0;
      this.hasLeaderCardInDeck = false;
      this.currentDecklistSeries = [];
      this.storedDecklist = null;
      this.storedSeries = null;
      this.deckId = null;
      this.favoriteCard = null;
      this.deckOwnerId = null;
      this.archetype = null;
      this.deckContent = null;
      this.deckOwner = null;
      this.notes = null;
      this.isPublic = true;
      this.selectedItems = '';
      this.deckName = '';
      this.currentlyViewedCardId = null;
      this.selectedCardId = null;
      this.saveDecklistToLocalStorage();
      this.updateTraits();
      // cardlistStore.resetCards(); // Reset the cardlist
    },
    setOrder(order) {
      if (this.orderBy === order) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.orderBy = order;
        this.sortDirection = 'asc';
      }
    },
    getOrderedDecklist(decklist) {
      const cardlistStore = useCardlistStore();
      const globalStore = useGlobalStore();
      if (!cardlistStore.gameConfig) return decklist;

      // First apply basic sorting if specified
      if (this.orderBy !== null) {
        if (this.orderBy === 'id') {
          decklist.sort((a, b) => {
            const valA = a[cardlistStore.gameConfig.cardId_column] || '';
            const valB = b[cardlistStore.gameConfig.cardId_column] || '';
            return this.sortDirection === 'asc' ? valA.localeCompare(valB) : valB.localeCompare(valA);
          });
        } else if (this.orderBy === 'name') {
          decklist.sort((a, b) => {
            const valA = a[cardlistStore.gameConfig.cardName_column] || '';
            const valB = b[cardlistStore.gameConfig.cardName_column] || '';
            return this.sortDirection === 'asc' ? valA.localeCompare(valB) : valB.localeCompare(valA);
          });
        } else if (this.orderBy === 'cost') {
          decklist.sort((a, b) => {
            const valA = a[cardlistStore.gameConfig.cardCost_column] || 0;
            const valB = b[cardlistStore.gameConfig.cardCost_column] || 0;
            return this.sortDirection === 'asc' ? valA - valB : valB - valA;
          });
        }
      }

      // Then apply custom ordering if configured
      const config = globalStore.gameConfig;
      if (!config) return decklist;

      if (config["ordering-list"]?.length > 0) {
        this.applyOrderingList(decklist, config["ordering-list"]);
      }

      return decklist;
    },

    applyOrderingList(cards, orderingList) {
      cards.sort((a, b) => {
        for (const order of orderingList) {
          const column = order["custom-ordering"];
          const values = order["custom-ordering-column"];
          const useContains = order["contains"] === true;

          const aValue = a[column];
          const bValue = b[column];

          const aIndex = useContains && aValue ? 
            values.findIndex(value => aValue.includes(value)) : 
            values.indexOf(aValue);
          const bIndex = useContains && bValue ? 
            values.findIndex(value => bValue.includes(value)) : 
            values.indexOf(bValue);

            if (aIndex !== bIndex) {
            const result = aIndex - bIndex;
            return result;
          }
        }
        return 0;
      });
    },
    getGroupedDecklist() {
      const cardlistStore = useCardlistStore();
      if (!cardlistStore.gameConfig) return [];
      let orderedDecklist = this.getOrderedDecklist(this.getDecklistDetails());
      const deckSeparatorsColumn = cardlistStore.gameConfig.deckSeparators_column;
      const deckSeparators = cardlistStore.gameConfig.deckSeparators;
      const leaderCard = this.leaderCard;
      const otherCards = [];
      let firstSeparatorProcessed = false;
      let groupedSeparators = [];
      let groupedSeparatorCards = [];
      let grouped = [];

      deckSeparators.forEach((separatorType) => {
        const cardsOfType = orderedDecklist.filter(card =>
          card[deckSeparatorsColumn] === separatorType && (!leaderCard || card !== leaderCard)
        );

        if (cardsOfType.length > 0) {
          if (!firstSeparatorProcessed) {
            grouped.push({ type: 'separator', separatorType });
            grouped.push(...cardsOfType);
            firstSeparatorProcessed = true;
          } else {
            groupedSeparators.push(separatorType);
            groupedSeparatorCards.push(...cardsOfType);
          }
        }
      });

      if (groupedSeparators.length > 0) {
        const concatenatedSeparatorName = groupedSeparators.join(' & ');
        grouped.push({ type: 'separator', separatorType: concatenatedSeparatorName });
        grouped.push(...groupedSeparatorCards);
      }

      orderedDecklist.forEach(card => {
        if (!deckSeparators.includes(card[deckSeparatorsColumn]) && (!leaderCard || card !== leaderCard)) {
          otherCards.push(card);
        }
      });

      if (otherCards.length > 0) {
        grouped.push({ type: 'separator', separatorType: 'Others' });
        grouped.push(...otherCards);
      }

      return grouped;
    },
    saveDecklistToLocalStorage() {
      const cardlistStore = useCardlistStore();
      if (this.deckId === null && !cardlistStore.customSeriesSelected) {
        const prefix = cardlistStore.gameConfig.gameid + '_';
        localStorage.setItem(prefix + 'storedDecklist', JSON.stringify(this.currentDecklist));
        localStorage.setItem(prefix + 'storedSeries', JSON.stringify(this.currentDecklistSeries));
        localStorage.setItem(prefix + 'selectedItems', this.selectedItems);
        localStorage.setItem(prefix + 'notes', this.notes); // Save notes
        localStorage.setItem(prefix + 'favoriteCard', this.favoriteCard);
        localStorage.setItem(prefix + 'deckName', this.deckName); // Save deck name
        localStorage.setItem(prefix + 'isPublic', this.isPublic); // Save public status
        localStorage.setItem(prefix + 'archetype', this.archetype); // Save archetype
        localStorage.setItem(prefix + 'customSeries', JSON.stringify(this.customSeries)); // Add this line to store custom series
      }
    },
    loadDecklistFromLocalStorage() {
      const cardlistStore = useCardlistStore();
      const gameConfig = cardlistStore.gameConfig;
      
      if (this.deckId === null && !cardlistStore.customSeriesSelected) {
        const prefix = useCardlistStore().gameConfig.gameid + '_';
        const storedDecklist = localStorage.getItem(prefix + 'storedDecklist');
        const storedSelectedItems = localStorage.getItem(prefix + 'selectedItems');
        const storedNotes = localStorage.getItem(prefix + 'notes'); // Load notes
        const storedFavoriteCard = localStorage.getItem(prefix + 'favoriteCard');
        const storedDeckName = localStorage.getItem(prefix + 'deckName'); // Load deck name
        const storedIsPublic = localStorage.getItem(prefix + 'isPublic'); // Load public status
        const storedArchetype = localStorage.getItem(prefix + 'archetype'); // Load archetype
        const storedCustomSeries = localStorage.getItem(prefix + 'customSeries'); // Add this line to get stored custom series

        if (storedDecklist && storedDecklist !== '[]') {
          this.currentDecklist = JSON.parse(storedDecklist);
          this.selectedItems = storedSelectedItems || '';
          this.notes = storedNotes === "null" ? null : storedNotes || ''; // Set notes to null if "null"
          this.favoriteCard = storedFavoriteCard || null;
          this.deckName = storedDeckName || ''; // Set deck name
          this.isPublic = storedIsPublic === 'true'; // Set public status
          this.archetype = storedArchetype || null; // Set archetype
          this.customSeries = storedCustomSeries ? JSON.parse(storedCustomSeries) : []; // Add this line to set custom series
          this.customSeries.forEach(series => {
            cardlistStore.selectCustomSeries(series, false);
          });

          const seriesToLoad = new Set();
          this.currentDecklist.forEach(card => {
            let seriesFound = false;
            for (const [key, series] of Object.entries(gameConfig.series)) {
              const cardIdLower = card[gameConfig.cardId_column].toLowerCase();
              let keyLower = key.replace(/-/g, '').replace(/\//g, '').toLowerCase();
              if (keyLower.endsWith('-')) {
                keyLower = keyLower.slice(0, -1);
              }
              if (typeof key === 'string' && cardIdLower.includes(keyLower) && !cardlistStore.cardCache[series]) {
                seriesToLoad.add(keyLower);
                seriesFound = true;
              } else {
              }
            }
            if (!seriesFound && gameConfig.wildcardSeries) {
              seriesToLoad.add(gameConfig.wildcardSeries);
            }
          });

          if (seriesToLoad.size > 0) {
            cardlistStore.selectedSeries = Array.from(seriesToLoad);
            cardlistStore.loadCards().then(() => {
              this.updateTotalCardsInDeck();
            });
          } else {
            cardlistStore.init(cardlistStore.gameConfig, true);
            this.updateTotalCardsInDeck();
          }
        } else {
          this.currentDecklist = [];
          this.currentDecklistSeries = [];
          this.selectedItems = '';
          this.notes = null; // Initialize notes as null
          this.favoriteCard = null;
          this.deckName = '';
          this.isPublic = true;
          this.archetype = null; // Initialize archetype as null
        }
      }
    },
    loadSeriesIfNeeded() {
      const cardlistStore = useCardlistStore();
      if (!cardlistStore.init) return Promise.resolve();
      const missingSeries = this.currentDecklistSeries.filter(series => series !== null && !cardlistStore.cardCache[series]);
      
      const newSelectedSeries = new Set(cardlistStore.selectedSeries);
      missingSeries.forEach(series => {
        let lowerSeries = series.toLowerCase();
        if (lowerSeries.endsWith('-')) {
          lowerSeries = lowerSeries.slice(0, -1);
        }
        newSelectedSeries.add(lowerSeries);
      });
      
      cardlistStore.selectedSeries = Array.from(newSelectedSeries);
      return cardlistStore.loadCards();
    },
    init(deckId = null) {
      const cardlistStore = useCardlistStore();
      const globalStore = useGlobalStore();
      this.deckId = deckId;
      if (this.deckId === null) {
        this.softClearDecklist(); // Use soft clear when switching to a local deck
        this.loadDecklistFromLocalStorage();
        cardlistStore.cardlistVisible = true;
        this.isOwnedByUser = true;
      } else {
        this.loadDeck(deckId).then(() => {
          this.isOwnedByUser = this.deckOwnerId === globalStore.userData.id;
          cardlistStore.cardlistVisible = this.isOwnedByUser;
          globalStore.addToast({
            message: "CreatedUser",
            type: 'confirm',
            toastType: 'userDeckToast',
            content: {
              username: this.deckOwner.username,
              id: this.deckOwner.id,
              avatar: this.deckOwner.avatar,
              selectedBadge: this.deckOwner.selectedBadge,
              selectedAvatarBackground: this.deckOwner.selectedAvatarBackground
            }
          });
        });
      }
    },
    async loadDeck(deckId) {
      this.currentDecklist = [];
      if (this.deckMode === true) this.deckMode = null;
      if (deckId) {
        const cardlistStore = useCardlistStore();
        const globalStore = useGlobalStore();
        if (!cardlistStore.gameConfig) return;
        const gameConfig = cardlistStore.gameConfig;
        try {
          const response = await fetch(`${gameConfig.startUrl}/${gameConfig.gameid}/external/decklist-functions.php`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams({
              action: 'getDecklist',
              gameid: gameConfig.decklists_table,
              deckId: deckId,
            })
          });
          const result = await response.json();
          if (result.success) {
            const decklistContent = result.decklist.decklistcontent.split('\n').filter(line => line.trim() !== '');
            this.currentDecklist = decklistContent.map(line => {
              const [count, cardNo] = line.split(' x ');
              return {
                [gameConfig.cardId_column]: cardNo.trim(),
                count: parseInt(count.trim(), 10)
              };
            });
            this.favoriteCard = result.decklist.favoriteCard;
            this.deckOwnerId = result.decklist.decklistownerid;
            this.archetype = result.decklist.archetype;
            this.deckContent = result.decklist.content || null; // Update deck content
            this.deckName = result.decklist.decklistname || null;
            this.deckOwner = result.decklist.userData || null;
            this.notes = result.decklist.notes || null; // Load notes from server
            this.isPublic = result.decklist.is_public ? true : false;
            this.selectedItems = result.decklist.selectedItems || '';
            this.deckId = deckId; // Ensure deckId is set
            this.customSeries = result.decklist.customSeries ? result.decklist.customSeries.split(',').map(series_map => ({ series_map })) : []; // Add this line to set custom series
            this.customSeries.forEach(series => {
              cardlistStore.selectCustomSeries(series,false);
            });

            const seriesToLoad = new Set();
            this.currentDecklist.forEach(card => {
              let seriesFound = false;
              for (const [key, series] of Object.entries(gameConfig.series)) {
                const cardIdLower = card[gameConfig.cardId_column].toLowerCase();
                let keyLower = key.replace(/-/g, '').replace(/\//g, '').toLowerCase();
                if (keyLower.endsWith('-')) {
                  keyLower = keyLower.slice(0, -1);
                }
                if (typeof key === 'string' && cardIdLower.includes(keyLower) && !cardlistStore.cardCache[series]) {
                  seriesToLoad.add(keyLower);
                  seriesFound = true;
                } else {
                }
              }
              if (!seriesFound && gameConfig.wildcardSeries && this.customSeries.length === 0) {
                seriesToLoad.add(gameConfig.wildcardSeries);
              }
            });

            if (seriesToLoad.size > 0) {
              cardlistStore.selectedSeries = Array.from(seriesToLoad);
              cardlistStore.loadCards().then(() => {
                this.updateTotalCardsInDeck();
              });
            } else {
              cardlistStore.init(cardlistStore.gameConfig, true);
              this.updateTotalCardsInDeck();
            }

            // Ensure userCheck is not null before setting isOwnedByUser
            const checkUserOwnership = () => {
              if (globalStore.userCheck !== null) {
                this.isOwnedByUser = this.deckOwnerId === globalStore.userData.id;
                if (this.isOwnedByUser) {
                  cardlistStore.cardlistVisible = true;
                  //if series * 
                  if (gameConfig.seriesLoadedAtStart && gameConfig.seriesLoadedAtStart == '*') {
                    cardlistStore.selectedSeries = Object.keys(globalStore.gameConfig.series).map(key => {
                      let lowerKey = key.toLowerCase();
                      if (lowerKey.endsWith('-')) {
                        lowerKey = lowerKey.slice(0, -1);
                      }
                      return lowerKey;
                    });
                  }
                } else {
                  cardlistStore.cardlistVisible = false;
                }
              } else {
                setTimeout(checkUserOwnership, 100); // Wait and check again
              }
            };
            checkUserOwnership();

          } else {
            globalStore.addToast({
              message: 'Failed to get decklist',
              type: 'error',
            });
          }
        } catch (error) {
          globalStore.addToast({
            message: `Error loading deck: ${error.message}`,
            type: 'error',
          });
        }
      } else {
        // If it's a local deck, use userData as deckOwner
        const globalStore = useGlobalStore();
        this.deckOwner = globalStore.userData;
      }
    },
    updateTraits() {
      const cardlistStore = useCardlistStore();
      if (!cardlistStore.gameConfig) return;
      const cardIdColumn = cardlistStore.gameConfig.cardId_column;
      const traitsColumn = cardlistStore.gameConfig.cardTraits_column;
      const traitCountMap = new Map();
      const delimiter = cardlistStore.gameConfig.cardTraits_delimiter || '|';

      this.currentDecklist.forEach(deckCard => {
        const cardDetails = cardlistStore.cards.find(card => card[cardIdColumn] === deckCard[cardIdColumn]);
        if (cardDetails && cardDetails[traitsColumn]) {
          const traitsArray = cardDetails[traitsColumn].split(delimiter).map(trait => trait.trim());
          traitsArray.forEach(trait => {
            const countIncludingDeck = traitCountMap.has(trait) ? traitCountMap.get(trait) + deckCard.count : deckCard.count;
            traitCountMap.set(trait, countIncludingDeck);
          });
        }
      });

      this.totalTraits = Array.from(traitCountMap).map(([name, count]) => ({ name, count }));
    },
    selectFavoriteCard(cardId) {
      this.favoriteCard = cardId;
      const prefix = useCardlistStore().gameConfig.gameid + '_';
      localStorage.setItem(prefix + 'favoriteCard', this.favoriteCard);
    },
    selectDeckItems(items) {
      this.selectedItems = items; // Correctly update the selectedItems state
      const prefix = useCardlistStore().gameConfig.gameid + '_';
      localStorage.setItem(prefix + 'selectedItems', items);
    },
    getSelectedItemsFromLocalStorage() {
      const prefix = useCardlistStore().gameConfig.gameid + '_';
      return localStorage.getItem(prefix + 'selectedItems') || '';
    },
    updateDeckName(name) {
      this.deckName = name;
    },
    importDecklistFromFile(file) {
      const globalStore = useGlobalStore();
      const reader = new FileReader();
      reader.onload = (event) => {
        const decklistText = event.target.result;
        const decklist = [];
        const lines = decklistText.split('\n');
        lines.forEach(line => {
          const [count, cardNo] = line.split(' x ');
          if (cardNo && count) {
            decklist.push({
              [useCardlistStore().gameConfig.cardId_column]: cardNo.trim(),
              count: parseInt(count.trim(), 10)
            });
          }
        });
        try {
          this.resetDeckbuilder();
          this.currentDecklist = decklist;
          this.determineSeriesFromDecklist(); // Call determineSeriesFromDecklist after resetting the deckbuilder
          this.updateTotalCardsInDeck();
          this.saveDecklistToLocalStorage();
          globalStore.addToast({
            message: 'Decklist imported successfully',
            type: 'success',
          });
        } catch (error) {
          globalStore.addToast({
            message: `Error importing decklist: ${error.message}`,
            type: 'error',
          });
        }
      };
      reader.readAsText(file);
    },
    importDecklistFromText(decklistText) {
      const cardlistStore = useCardlistStore();
      const globalStore = useGlobalStore();
      const decklist = [];
      const lines = decklistText.split('\n');

      // Initialize cardlist if not already initialized
      if (cardlistStore.state == 'Init') {
        cardlistStore.init(globalStore.gameConfig, true);
        this.isOutsideDeckbuilder = true;
      }

      lines.forEach(line => {
        const [count, cardNo] = line.split(' x ');
        if (cardNo && count) {
          decklist.push({
            [globalStore.gameConfig.cardId_column]: cardNo.trim(),
            count: parseInt(count.trim(), 10)
          });
        }
      });

      try {
        this.resetDeckbuilder();
        this.currentDecklist = decklist;
        this.determineSeriesFromDecklist(); // Call determineSeriesFromDecklist after resetting the deckbuilder
        this.updateTotalCardsInDeck();

        // Set the first card as the favorite card
        if (decklist.length > 0) {
          this.favoriteCard = decklist[0][globalStore.gameConfig.cardId_column];
        }

        // Fetch the series and load the cards if outside deckbuilder
        return cardlistStore.loadCards().then(() => {
          const invalidCards = this.currentDecklist.filter(deckCard => 
            !cardlistStore.cards.some(card => card[globalStore.gameConfig.cardId_column] === deckCard[globalStore.gameConfig.cardId_column])
          );

          if (invalidCards.length > 0) {
            invalidCards.forEach(card => {
              globalStore.addToast({
                message: `Invalid card number: ${card[cardlistStore.gameConfig.cardId_column]}`,
                type: 'error',
              });
            });
            this.resetDeckbuilder();
            return;
          }

          this.getArchetypes();
          const colors = this.getUniqueColors();
          const seriesNames = this.currentDecklistSeries.map(seriesId => {
              return globalStore.gameConfig.series[seriesId]?.name || seriesId;
          }).join(', ');
          // Generate deck name based on deckColors and series names
          this.deckName = `${colors} ${seriesNames}`;

          // Save the decklist on the server
          return this.saveDecklistToServer().then(() => {
            globalStore.addToast({
              message: 'Decklist imported successfully',
              type: 'success',
            });
            return { series: this.currentDecklistSeries, colors, deckId: this.deckId };
          });
        });
      } catch (error) {
        globalStore.addToast({
          message: `Error importing decklist: ${error.message}`,
          type: 'error',
        });
      }
    },
    determineSeriesFromDecklist() {
      const cardlistStore = useCardlistStore();
      const gameConfig = cardlistStore.gameConfig;
      let seriesToLoad = new Set();

      if (gameConfig.seriesLoadedAtStart && gameConfig.seriesLoadedAtStart === '*') {
        seriesToLoad = new Set(Object.keys(gameConfig.series).map(key => {
          let lowerKey = key.toLowerCase();
          if (lowerKey.endsWith('-')) {
            lowerKey = lowerKey.slice(0, -1);
          }
          return lowerKey;
        }));
      } else {
        this.currentDecklist.forEach(card => {
          let seriesFound = false;
          for (const [key, series] of Object.entries(gameConfig.series)) {
            const cardIdLower = card[gameConfig.cardId_column].toLowerCase();
            let keyLower = key.replace(/-/g, '').replace(/\//g, '').toLowerCase();
            if (keyLower.endsWith('-')) {
              keyLower = keyLower.slice(0, -1);
            }
            if (typeof key === 'string' && cardIdLower.includes(keyLower)) {
              seriesToLoad.add(keyLower);
              seriesFound = true;
            }
          }
          if (!seriesFound && gameConfig.wildcardSeries) {
            seriesToLoad.add(gameConfig.wildcardSeries);
          }
        });
      }

      this.currentDecklistSeries = Array.from(seriesToLoad);
      cardlistStore.selectedSeries = Array.from(seriesToLoad);

      // Call loadSeriesIfNeeded after determining the series
      this.loadSeriesIfNeeded().then(() => {
        this.updateTotalCardsInDeck(); // Ensure the total cards in deck is updated
      }).catch(error => {
        console.error('Error loading series:', error);
      });
    },
    resetDeckbuilder() {
      this.currentDecklist = [];
      this.totalCardsInDeck = 0;
      this.favoriteCard = null;
      this.deckOwnerId = null;
      this.archetype = null;
      this.deckContent = null;
      this.deckId = null;
      this.deckName = '';
      this.deckOwner = null;
      this.notes = null;
      this.isPublic = true;
      this.selectedItems = '';
      this.saveDecklistToLocalStorage();
    },
    setCurrentlyViewedCard(id) {
      this.currentlyViewedCardId = id;
    },
    clearCurrentlyViewedCard() {
      this.currentlyViewedCardId = null;
    },
    setSelectedCardId(id) {
      if (id == this.selectedCardId && this.selectedCardId != null) {
        this.selectedCardId = null;
      } else {
        this.selectedCardId = id;
      }
    },
    clearSelected() {
      this.currentlyViewedCardId = null;
      this.selectedCardId = null;
    },
    saveDecklistToServer() {
      const cardlistStore = useCardlistStore();
      const globalStore = useGlobalStore();
      if (!cardlistStore.gameConfig) return Promise.reject('No game config available');
      const gameConfig = cardlistStore.gameConfig;
      const decklistContent = this.currentDecklist.map(card => `${card.count} x ${card[gameConfig.cardId_column]}`).join('\n');
      
      // Ensure customSeries is set correctly
      const customSeries = cardlistStore.customSeriesSelected ? [cardlistStore.customSeriesSelected.series_map] : [];

      const favoriteCard = this.favoriteCard ? cardlistStore.cards.find(card => card[gameConfig.cardId_column] === this.favoriteCard) : null;
      const favoriteCardLink = favoriteCard ? (favoriteCard.isCustom ? favoriteCard.customPNG : favoriteCard[gameConfig.cardFront_column]) : null;

      const payload = {
        action: 'saveDecklist',
        gameid: gameConfig.decklists_table,
        decklistId: this.deckId, // Ensure deckId is included in the payload
        decklistContent,
        favoriteCard: this.favoriteCard,
        favoriteCardLink: favoriteCardLink,
        decklistOwnerId: this.deckOwnerId,
        archetype: this.archetype,
        content: this.deckContent,
        decklistName: this.deckName || '(no name)', // Default to "(no name)" if no name is set
        userData: this.deckOwner,
        notes: this.notes,
        is_public: this.isPublic ? 1 : 0,
        selectedItems: this.selectedItems,
        deckColors: this.getUniqueColors(),
        customSeries: customSeries.join(','), // Use customSeries from cardlistStore
      };

      return fetch(`${gameConfig.startUrl}/${gameConfig.gameid}/external/decklist-functions.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(payload),
      })
        .then(response => response.json())
        .then(result => {
          if (result.success) {
            if (!this.deckId) {
              this.deckId = result.id; // Set the deckId to the received ID if it's a new deck
              const url = new URL(window.location);
              url.searchParams.set('deckId', this.deckId);
              window.history.pushState({}, '', url);
              // Clear local storage after successful online save
              const prefix = cardlistStore.gameConfig.gameid + '_';
              localStorage.removeItem(prefix + 'storedDecklist');
              localStorage.removeItem(prefix + 'storedSeries');
              localStorage.removeItem(prefix + 'selectedItems');
              localStorage.removeItem(prefix + 'notes');
              localStorage.removeItem(prefix + 'favoriteCard');
              localStorage.removeItem(prefix + 'deckName');
              localStorage.removeItem(prefix + 'isPublic');
              localStorage.removeItem(prefix + 'archetype');
              localStorage.removeItem(prefix + 'customSeries'); // Add this line to remove custom series
            }
            globalStore.addToast({
              message: 'Decklist saved successfully',
              type: 'success',
            });
          } else {
            globalStore.addToast({
              message: 'Failed to save decklist',
              type: 'error',
            });
          }
        })
        .catch(error => {
          globalStore.addToast({
            message: `Error saving decklist: ${error.message}`,
            type: 'error',
          });
        });
    },
    saveDecklistToFile() {
      try {
        const decklistText = this.exportDecklistAsText();
        const blob = new Blob([decklistText], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'decklist.txt';
        link.click();
        URL.revokeObjectURL(url);
      } catch (error) {
        const globalStore = useGlobalStore();
        globalStore.addToast({
          message: `Error saving decklist to file: ${error.message}`,
          type: 'error',
        });
      }
    },
    resetStore() {
      this.$reset();
      const prefix = useCardlistStore().gameConfig.gameid + '_';
      localStorage.removeItem(prefix + 'storedDecklist');
      localStorage.removeItem(prefix + 'storedSeries');
      localStorage.removeItem(prefix + 'selectedItems');
      localStorage.removeItem(prefix + 'notes');
      localStorage.removeItem(prefix + 'favoriteCard');
      localStorage.removeItem(prefix + 'deckName');
      localStorage.removeItem(prefix + 'isPublic');
    },
    getUniqueColors() {
      const cardlistStore = useCardlistStore();
      if (!cardlistStore.gameConfig) return '';
      const colorColumn = cardlistStore.gameConfig.cardColor_column;
      const maxCardsPerDeck = cardlistStore.gameConfig.maximumCardsPerDeck;
      const minCount = maxCardsPerDeck * 0.2;

      const colorCounts = this.getDecklistDetails().reduce((acc, card) => {
        const color = card[colorColumn];
        if (color) {
          acc[color] = (acc[color] || 0) + card.count;
        } else {
        }
        return acc;
      }, {});

      const uniqueColors = Object.keys(colorCounts).filter(color => {
        const isUnique = colorCounts[color] >= minCount;
        return isUnique;
      });

      return uniqueColors.join(',');
    },
    getArchetypes() {
      const cardlistStore = useCardlistStore();
      const gameConfig = cardlistStore.gameConfig;
      const archetypes = gameConfig.archetypes;
      const minCards = gameConfig["archetype-min-cards"];
      const archetypeCounts = {};

      this.currentDecklist.forEach(card => {
        const cardId = card[gameConfig.cardId_column];
        const cardCount = card.count;

        for (let archetype in archetypes) {
          if (archetypes[archetype].cards.some(archetypeCardId => cardId.includes(archetypeCardId))) {
            archetypeCounts[archetype] = (archetypeCounts[archetype] || 0) + cardCount;
          }
        }
      });

      const result = Object.keys(archetypeCounts)
        .filter(archetype => archetypeCounts[archetype] >= minCards);

      this.archetype = result.join(",");
    },
    toggleDeckMode() {
      this.deckMode = this.deckMode === null ? false : !this.deckMode;
    },
    softClearDecklist() {
      this.currentDecklist = [];
      this.leaderCard = null;
      this.totalCardsInDeck = 0;
      this.hasLeaderCardInDeck = false;
      this.currentDecklistSeries = [];
      this.storedDecklist = null;
      this.storedSeries = null;
      this.favoriteCard = null;
      this.archetype = null;
      this.isPublic = true;
      this.selectedItems = '';
      this.deckName = '';
      this.currentlyViewedCardId = null;
      this.selectedCardId = null;
      this.updateTraits();
    },
    cloneDeck() {
      this.deckId = null;
      this.deckOwnerId = null;
      this.saveDecklistToLocalStorage();
      const url = new URL(window.location);
      url.searchParams.delete('deckId');
      window.history.replaceState({}, '', url);
      this.init();
    },
    importDecklist(deckId) {
      this.loadDeck(deckId).then(() => {
        this.saveDecklistToServer().then(() => {
          const newPlayerDeckLink = document.getElementById('newPlayerDeckLink');
          if (newPlayerDeckLink) {
            newPlayerDeckLink.dataset.deckId = this.deckId;
            newPlayerDeckLink.dataset.isDecklistCreator = 'false';
          }
          this.deckId = deckId;
        });
      });
    },
  },
  watch: {
    currentDecklist: {
      handler() {
        if (this.deckId === null) {
          this.saveDecklistToLocalStorage();
        }
      },
      deep: true,
    },
    favoriteCard(newVal) {
      if (this.deckId === null) {
        const prefix = useCardlistStore().gameConfig.gameid + '_';
        localStorage.setItem(prefix + 'favoriteCard', newVal);
      }
    },
    deckName(newVal) {
      if (this.deckId === null) {
        const prefix = useCardlistStore().gameConfig.gameid + '_';
        localStorage.setItem(prefix + 'deckName', newVal);
      }
    },
    notes(newVal) {
      if (this.deckId === null) {
        const prefix = useCardlistStore().gameConfig.gameid + '_';
        localStorage.setItem(prefix + 'notes', newVal);
      }
    },
    isPublic(newVal) {
      if (this.deckId === null) {
        const prefix = useCardlistStore().gameConfig.gameid + '_';
        localStorage.setItem(prefix + 'isPublic', newVal);
      }
    },
    selectedItems(newVal) {
      if (this.deckId === null) {
        const prefix = useCardlistStore().gameConfig.gameid + '_';
        localStorage.setItem(prefix + 'selectedItems', newVal);
      }
    },
    archetype(newVal) {
      if (this.deckId === null) {
        const prefix = useCardlistStore().gameConfig.gameid + '_';
        localStorage.setItem(prefix + 'archetype', newVal);
      }
    },
  }
});
