import { defineStore } from 'pinia';
import { useDeckbuilderStore } from './deckbuilderStore';
import { useGlobalStore } from './globalStore'; // Import the global store

export const useCardlistStore = defineStore('cardlistStore', {
  state: () => ({
    state: 'Init', // Possible states: Init, URLQueryHandler, SelectSeries, PickMultipleSeries, LoadCards, Loaded
    selectedSeries: [],
    cards: [], // Displayed cards in the cardlist
    cardCache: {}, // Cache to hold up to 4 series at once
    cardSize: localStorage.getItem('cardSize') ? parseFloat(localStorage.getItem('cardSize')) : 1.5, // Default card size
    seriesOptions: {}, // Series options from game config
    filteredCards: [], // Filtered cards based on search query
    modal_watchingList: null, // List of cards being watched in the modal
    modal_currentIndex: null, // Current index of the card in the modal
    modal_selectedCard: null, // Currently selected card in the modal
    showModalFlag: false, // Flag to show or hide the modal
    isDeckbuilding: false, // Mode switch for deckbuilding
    selectedColors: [],
    filters: {
      rangeFilters: {},
      singleRangeFilters: {},
      pickerFilters: {},
      optionSetFilters: {},
      booleanFilters: {} // Add this line to track boolean filters
    },
    filterWindowOpened: false, // Add this line to track if the filter window is opened
    order: {
      id: 'ascending',
      cost: 'unset',
      name: 'unset'
    },
    cardlistVisible: false,
    customSeriesSelected: null, // Add this line to store the selected custom series
    customSeries: [], // Add this line to store fetched custom series
    isCustomSeriesState: false, // Add this line to track if custom series state is active
  }),
  actions: {
    init(gameConfig, isDeckbuilding) {
      //console.log('init called');
      this.state = 'Init';
      this.customSeriesSelected = null;
      this.customSeries = [];
      this.isCustomSeriesState = false;
      this.selectedSeries = [];
      this.cards = [];
      this.isDeckbuilding = isDeckbuilding;
      this.seriesOptions = gameConfig.series; // Initialize series options
      this.gameConfig = gameConfig;
      this.order = {
        id: 'ascending',
        cost: 'unset',
        name: 'unset'
      };
      if (!isDeckbuilding) {
        this.cardlistVisible = true;
      } else {
        this.cardlistVisible = false;
      }
      // Load saved boolean filters
      const savedFilters = localStorage.getItem(`${this.gameConfig.gameid}_saved_filters`) || '';
      const filterNames = savedFilters.split(',').filter(Boolean);
      filterNames.forEach(uniqueName => {
        const filter = gameConfig.filters.find(f => f.uniqueName === uniqueName);
        if (filter) {
          const savedValue = JSON.parse(localStorage.getItem(`${this.gameConfig.gameid}_filterValue_${filter.name}`));
          if (!this.filters.booleanFilters[filter.property] && savedValue) {
            this.filters.booleanFilters[filter.property] = card => {
              if (filter.validValues) {
                return filter.validValues.includes(card[filter.property]);
              } else if (filter.invalidValues) {
                return !filter.invalidValues.includes(card[filter.property]);
              } else if (filter.neededValue !== undefined) {
                return card[filter.property] === filter.neededValue;
              } else {
                return card[filter.property] === savedValue;
              }
            };
          }
        }
      });

      this.handleURLQuery();

      // Ensure state transition if series are already selected
      if (this.selectedSeries.length > 0) {
        // this.state = 'LoadCards';
        // console.log('loadCards called from init');
        // this.loadCards();
      } else {
        this.state = 'SelectSeries'; // Ensure state transition to SelectSeries if no series are selected
      }
    },
    initAsAdmin(gameConfig, seriesToLoadAtStart) {
      this.state = 'Init';
      this.selectedSeries = [];
      this.cards = [];
      this.isDeckbuilding = false;
      this.seriesOptions = gameConfig.series; // Initialize series options
      this.gameConfig = gameConfig;
      this.order = {
        id: 'ascending',
        cost: 'unset',
        name: 'unset'
      };
      this.cardlistVisible = true;
      // Overwrite the series to load at start
      this.gameConfig.seriesLoadedAtStart = seriesToLoadAtStart;

      this.handleURLQuery();

      // Ensure state transition if series are already selected
      if (this.selectedSeries.length > 0) {
        this.state = 'LoadCards';
        this.loadCards();
      } else {
        this.state = 'SelectSeries'; // Ensure state transition to SelectSeries if no series are selected
      }
    },
    handleURLQuery() {
      const urlParams = new URLSearchParams(window.location.search);
      const seriesParam = urlParams.get('series');
      const customSeriesParam = urlParams.get('customSeries');
      
      if (seriesParam) {
        const seriesList = seriesParam.split(',').map(series => series.toLowerCase().endsWith('-') ? series.slice(0, -1) : series.toLowerCase());
        this.selectedSeries = seriesList;
        this.state = 'LoadCards';
        this.loadCards();
      } else if (customSeriesParam) {
        this.fetchCustomSeries().then(() => {
          const selectedCustomSeries = this.customSeries.find(series => series.series_map === customSeriesParam);
          if (selectedCustomSeries) {
            this.selectCustomSeries(selectedCustomSeries);
          }
        });
      } else {
        if (this.gameConfig.seriesLoadedAtStart && this.gameConfig.seriesLoadedAtStart == '*') {
          this.selectedSeries = Object.keys(this.gameConfig.series).map(key => {
            let lowerKey = key.toLowerCase();
            if (lowerKey.endsWith('-')) {
              lowerKey = lowerKey.slice(0, -1);
            }
            return lowerKey;
          });
          this.state = 'LoadCards';
          this.loadCards();
        } else {
          this.state = 'SelectSeries';
        }
      }
    },
    selectSeries(seriesKey) {
      this.selectedSeries = [seriesKey.toLowerCase()];
      this.updateURLQuery();
      if (this.state !== 'PickMultipleSeries') {
        this.state = 'LoadCards';
        this.loadCards();
      }
    },
    setSeries(series) {
      if (this.gameConfig.seriesLoadedAtStart && this.gameConfig.seriesLoadedAtStart == '*') {
        this.selectedSeries = Object.keys(this.gameConfig.series).map(s => s.toLowerCase().endsWith('-') ? s.slice(0, -1) : s.toLowerCase());
      } else {
        this.selectedSeries = series.map(s => s.toLowerCase());
      }
      this.clearFilters(); // Add this line to clear filters when series changes
      this.applySeries();
    },
    addSeries(seriesKey) {
      const lowerCaseKey = seriesKey.toLowerCase();
      if (!this.selectedSeries.includes(lowerCaseKey)) {
        this.selectedSeries.push(lowerCaseKey);
      }
      this.updateURLQuery();
    },
    applySeries() {
      this.clearFilters();
      const globalStore = useGlobalStore();
      try {
        this.cards = []; // Clear current cards to avoid mixing items
        this.state = 'LoadCards';
        this.loadCards().then(() => {
          // Ensure the series is loaded correctly from cache
          if (this.state === 'Loaded') {
            this.applyFilters();
          }
        });
        this.updateURLQuery();
      } catch (error) {
        globalStore.addToast({
          message: `Error applying series: ${error.message}`,
          type: 'error',
        });
      }
    },
    updateURLQuery() {
      const urlParams = new URLSearchParams(window.location.search);
      const cleanedSeries = this.selectedSeries.map(series => series.toLowerCase().endsWith('-') ? series.slice(0, -1) : series.toLowerCase());
      
      if (cleanedSeries.length > 0) {
        urlParams.set('series', cleanedSeries.join(','));
      } else {
        urlParams.delete('series');
      }
      
      if (this.customSeriesSelected) {
        urlParams.set('customSeries', this.customSeriesSelected.series_map);
      } else {
        urlParams.delete('customSeries');
      }
      
      window.history.replaceState(null, '', '?' + urlParams.toString());
    },
    pickMultipleSeries() {
      this.state = 'PickMultipleSeries';
    },
    async loadCards() {
      const globalStore = useGlobalStore(); // Ensure globalStore is defined here
      //console.log('loadCards called');
      const promises = [];

      if (this.selectedSeries.length > 0) {
        globalStore.setLoading({ 
          isLoading: true, 
          loadingMessage: 'Loading cards...' 
        });

        const gameid = this.gameConfig.gameid;
        const gameConfig = this.gameConfig;
        const loadedAtStart = gameConfig.seriesLoadedAtStart == '*';
        let missingSeries = [];

        if (loadedAtStart) {
          missingSeries = this.selectedSeries;
        } else {
          missingSeries = this.selectedSeries.filter(seriesKey => {
            const seriesId = seriesKey.toLowerCase().endsWith('-') ? seriesKey.slice(0, -1).toLowerCase() : seriesKey.toLowerCase();
            return !this.cardCache[seriesId];
          });
        }

        //console.log('Missing series:', missingSeries);

        if (missingSeries.length > 0) {
          const seriesParam = missingSeries.map(seriesKey => seriesKey.replace(/-/g, '').toLowerCase()).join(',');
          const url = `https://exburst.dev/${gameid}/external/fetch_data.php?gameid=${gameid}&series=${loadedAtStart ? "*" : seriesParam}&seriesColumn=${gameConfig.series_column}`;
          //console.log('Fetching data from URL:', url);
          promises.push(fetch(url).then(response => response.json()).then(data => {
            //console.log('Fetched data:', data);

            if (Array.isArray(data)) {
              const newCards = data.filter(card =>
                this.selectedSeries.some(seriesKey =>
                  card[gameConfig.series_column].toLowerCase() === seriesKey.replace(/-/g, '').toLowerCase() || seriesKey === '*'
                )
              );

              //console.log('New cards:', newCards);

              const mappedCards = newCards.map((card, index) => {
                const formattedNumberPower = card[gameConfig.cardPower_column]
                  ? parseInt(card[gameConfig.cardPower_column].replace(/[^a-zA-Z0-9]/g, ''), 10) || 0
                  : null;

                return {
                  index: this.cards.length + index,
                  ...card,
                  top: 0,
                  left: 0,
                  width: this.cardSize,
                  height: this.cardSize * 1.397,
                  image: card[gameConfig.cardFront_column],
                  isAd: false,
                  formattedNumberPower: formattedNumberPower
                };
              });

              const uniqueNewCards = mappedCards.filter(newCard =>
                !this.cards.some(existingCard => existingCard[gameConfig.cardId_column] === newCard[gameConfig.cardId_column])
              );
              //console.log('Unique new cards:', uniqueNewCards);
              this.cards.push(...uniqueNewCards);

              missingSeries.forEach(seriesKey => {
                const seriesId = seriesKey.replace(/-/g, '').toLowerCase();
                this.cardCache[seriesId] = uniqueNewCards.filter(card =>
                  card[gameConfig.series_column].toLowerCase() === seriesId
                );
              });

              if (!loadedAtStart && Object.keys(this.cardCache).length > 4) {
                const oldestSeries = Object.keys(this.cardCache)[0];
                delete this.cardCache[oldestSeries];
              }
            } else {
              console.error('Error: Fetched data is not an array');
            }
            this.addExtraOptionSetFilters();
          }).catch(error => {
            console.error('Error loading cards:', error);
            globalStore.addToast({
              message: `Error loading cards: ${error.message}`,
              type: 'error',
            });
          }));
        }

        const cachedCards = this.selectedSeries.flatMap(seriesKey => {
          const seriesId = seriesKey.replace(/-/g, '').toLowerCase();
          return this.cardCache[seriesId] || [];
        });

        const uniqueCachedCards = cachedCards.filter(cachedCard =>
          !this.cards.some(existingCard => existingCard[gameConfig.cardId_column] === cachedCard[gameConfig.cardId_column])
        );
        //console.log('Unique cached cards:', uniqueCachedCards);
        this.cards.push(...uniqueCachedCards);
      }

      if (this.customSeriesSelected) {
        promises.push(fetch('https://exburst.dev/ua/external/custom_functions.php?action=load', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ 
            userId: globalStore.userData?.id || 1,
            series_map: this.customSeriesSelected.series_map // Ensure series_map is included
          })
        }).then(response => response.json()).then(data => {
          if (data.success) {
            const customCards = data.cards.map(card => ({
              ...card,
              isCustom: true // Mark as custom card
            }));
            this.cards.push(...customCards);
            console.log(this.cards);
          } else {
            console.error('Failed to load custom cards:', data.message);
          }
        }).catch(error => {
          console.error('Error loading custom cards:', error);
        }));
      }

      try {
        await Promise.all(promises);
      } catch (error) {
        console.error('Error loading cards:', error);
        globalStore.addToast({
          message: `Error loading cards: ${error.message}`,
          type: 'error',
        });
      } finally {
        globalStore.clearLoading();
        
      this.state = 'Loaded';
      this.applyFilters();
      }
    },
    async fetchCardsData() {
      try {

        const series = '*';
        const seriesColumn = 'series';
        const gameId = this.gameConfig.gameid;

        const response = await fetch(`${this.gameConfig.startUrl}/${gameId}/external/fetch_data_admin.php?gameid=${gameId}&series=${series}&seriesColumn=${seriesColumn}`);
        const data = await response.json();

        if (data.error) {
          throw new Error(data.error);
        }

        if (data.length > 0) {
          this.cards = data.map(item => ({ ...item, modified: false }));
        } else {
          this.cards = [];
        }
      } catch (error) {
        console.error('Error fetching card data:', error);
        const globalStore = useGlobalStore();
        globalStore.addToast({
          message: `Error fetching card data: ${error.message}`,
          type: 'error',
        });
      }
    },
    addExtraOptionSetFilters() {
      const extraOptions = new Set();
      this.cards.forEach(card => {
        const cardNo = card[this.gameConfig.cardId_column];
        if (cardNo) {
          if (cardNo.includes('ST')) {
            extraOptions.add('ST');
          }
          const match = cardNo.match(/-(\d)-/);
          if (match) {
            extraOptions.add(`-${match[1]}-`);
          }
        }
      });
      this.gameConfig.filters.forEach(filter => {
        if (filter.type === 'OptionSet' && filter.special) {
          filter.options = [...extraOptions];
        }
      });
    },
    populateWithAds(cards) {
      const globalStore = useGlobalStore();
      const screenWidth = window.innerWidth || window.screen.width;
      const isDevEnv = process.env.NODE_ENV === 'development';

      if ((globalStore.userData.roles && globalStore.userData.roles.includes("EX")) || screenWidth <= 1080/* || isDevEnv*/) {
        return cards; // Do not populate with ads if user has "EX" role, screen width is 1080 or less, or in development environment
      }

      const items = [...cards];
      const adFrequency = 30; // Insert an ad every 30 cards
      const firstAdPosition = 5; // First ad at position 5
      for (let i = firstAdPosition; i < items.length; i += adFrequency) {
        items.splice(i, 0, { isAd: true });
      }
      return items;
    },
    filterCards(query) {
      this.query = query; // Store the query for combined filtering
      this.applyFilters();
    },
    filterByColor(selectedColors) {
      this.selectedColors = selectedColors;
      this.applyFilters();
    },
    filterByRange(property, start, end, isEnabled) {
      if (isEnabled) {
        this.filters.rangeFilters[property] = { start, end };
      } else {
        delete this.filters.rangeFilters[property];
      }
      this.applyFilters();
    },
    filterBySingleRange(property, value, isEnabled, includes = false) {
      if (isEnabled) {
        this.filters.singleRangeFilters[property] = { value, includes };
      } else {
        delete this.filters.singleRangeFilters[property];
      }
      this.applyFilters();
    },
    filterByPicker(property, selectedItems, isEnabled) {
      if (isEnabled) {
        this.filters.pickerFilters[property] = { selectedItems, includes: this.filters.pickerFilters[property]?.includes || false };
      } else {
        delete this.filters.pickerFilters[property];
      }
      if (this.filters.pickerFilters[property] && this.filters.pickerFilters[property].selectedItems.length === 0) {
        delete this.filters.pickerFilters[property];
      }
      this.applyFilters();
    },
    filterByOptionSet(property, selectedOptions, isEnabled, includes = false, isSpecial = false) {
      if (isEnabled) {
        this.filters.optionSetFilters[property] = { selectedOptions, includes, isSpecial };
      } else {
        delete this.filters.optionSetFilters[property];
      }
      if (this.filters.optionSetFilters[property] && this.filters.optionSetFilters[property].selectedOptions.length === 0) {
        delete this.filters.optionSetFilters[property];
      }
      this.applyFilters();
    },
    filterByBoolean(property, uniqueName, value, isEnabled, filter) {

      if (isEnabled && value === true) {
        if (typeof value === 'function') {
          this.filters.booleanFilters[property] = value;
        } else {
          if (filter.validValues) {
            this.filters.booleanFilters[property] = card => filter.validValues.includes(card[property]);
          } else if (filter.invalidValues) {
            this.filters.booleanFilters[property] = card => !filter.invalidValues.includes(card[property]);
          } else if (filter.neededValue !== undefined) {
            this.filters.booleanFilters[property] = card => card[property] === filter.neededValue;
          } else {
            this.filters.booleanFilters[property] = card => card[property] === value;
          }
        }
      } else {
        delete this.filters.booleanFilters[property];
      }
      this.applyFilters();

      if (filter.saved) {
        localStorage.setItem(`${this.gameConfig.gameid}_filterValue_${filter.name}`, JSON.stringify(value));
        let savedFilters = localStorage.getItem(`${this.gameConfig.gameid}_saved_filters`) || '';
        const filterNames = savedFilters.split(',').filter(Boolean);
        if (isEnabled && value === true) {
          if (!filterNames.includes(uniqueName)) {
            filterNames.push(uniqueName);
          }
        } else {
          const index = filterNames.indexOf(uniqueName);
          if (index > -1) {
            filterNames.splice(index, 1);
          }
        }
        localStorage.setItem(`${this.gameConfig.gameid}_saved_filters`, filterNames.join(','));
      }
    },
    clearFilters() {
      this.filters.rangeFilters = {};
      this.filters.singleRangeFilters = {};
      this.filters.pickerFilters = {};
      this.filters.optionSetFilters = {};
      this.filters.booleanFilters = {};
      this.selectedColors = [];
      this.query = '';
      
      // Reload saved filters from localStorage
      const savedFilters = localStorage.getItem(`${this.gameConfig.gameid}_saved_filters`) || '';
      const filterNames = savedFilters.split(',').filter(Boolean);
      filterNames.forEach(uniqueName => {
        const filter = this.gameConfig.filters.find(f => f.uniqueName === uniqueName);
        if (filter) {
          const savedValue = JSON.parse(localStorage.getItem(`${this.gameConfig.gameid}_filterValue_${filter.name}`));
          if (savedValue) {
            this.filterByBoolean(filter.property, filter.uniqueName, true, true, filter);
          }
        }
      });

      this.applyFilters();
    },
    applyFilters() {
      const gameConfig = this.gameConfig;
      const textFilterColumns = gameConfig.textFilterColunms || [];
      const validColors = new Set(this.gameConfig.gameColors.map(color => color.value));
      const query = this.query || '';
      const selectedColors = this.selectedColors || [];

      // console.log('Applying filters...');
      // console.log('Query:', query);
      // console.log('Selected Colors:', selectedColors);
      // console.log('Range Filters:', this.filters.rangeFilters);
      // console.log('Single Range Filters:', this.filters.singleRangeFilters);
      // console.log('Picker Filters:', this.filters.pickerFilters);
      // console.log('Option Set Filters:', this.filters.optionSetFilters);
      // console.log('Boolean Filters:', this.filters.booleanFilters);

      const uniqueCards = new Set();
      let tempFilteredCards = this.cards.filter(card => {

        const matchesQuery = query.length < 3 || textFilterColumns.some(column => card[column] && card[column].toLowerCase().includes(query.toLowerCase()));

        const cardColor = card[this.gameConfig.cardColor_column] && validColors.has(card[this.gameConfig.cardColor_column].toLowerCase())
          ? card[this.gameConfig.cardColor_column].toLowerCase()
          : 'unknown';
        const matchesColor = selectedColors.length === 0 || selectedColors.includes(cardColor);

        const matchesRangeFilters = Object.entries(this.filters.rangeFilters).every(([property, { start, end }]) => {
          const value = card[property];
          const matches = value >= start && value <= end;
          return matches;
        });

        const matchesSingleRangeFilters = Object.entries(this.filters.singleRangeFilters).every(([property, { value, includes }]) => {
          const matches = includes ? card[property] && card[property].includes(value) : card[property] === value;
          return matches;
        });

        const matchesPickerFilters = Object.entries(this.filters.pickerFilters).every(([property, { selectedItems, includes }]) => {
          const matches = includes ? selectedItems.some(item => card[property] && card[property].includes(item)) : selectedItems.includes(card[property]);
          return matches;
        });

        const matchesOptionSetFilters = Object.entries(this.filters.optionSetFilters).every(([property, { selectedOptions, includes, isSpecial }]) => {
          const matches = selectedOptions.some(option => option.toLowerCase() === '(none)')
            ? card[property] === '' || card[property] === null || card[property] === 'null' || card[property] === undefined
            : includes ? selectedOptions.some(option => card[property] && card[property].toLowerCase().includes(option.toLowerCase()))
            : isSpecial ? selectedOptions.some(option => {
                if (option.startsWith('Set ')) {
                  const setNumber = option.slice(4);
                  return card[this.gameConfig.cardId_column] && card[this.gameConfig.cardId_column].includes(`-${setNumber}-`);
                }
                return card[this.gameConfig.cardId_column] && card[this.gameConfig.cardId_column].includes(option);
              })
            : selectedOptions.includes(card[property]);
          return matches;
        });

        const matchesBooleanFilters = Object.entries(this.filters.booleanFilters).every(([property, value]) => {
          const matches = typeof value === 'function' ? value(card) : card[property] === value;
          return matches;
        });

        const matchesAllFilters = matchesQuery && matchesColor && matchesRangeFilters && matchesSingleRangeFilters && matchesPickerFilters && matchesOptionSetFilters && matchesBooleanFilters;

        if (matchesAllFilters && !uniqueCards.has(card[gameConfig.cardId_column])) {
          uniqueCards.add(card[gameConfig.cardId_column]);
          return true;
        }
        return false;
      });

      // If no filters are applied and the filter window is not opened, display all cards
      if (Object.keys(this.filters.rangeFilters).length === 0 &&
        Object.keys(this.filters.singleRangeFilters).length === 0 &&
        Object.keys(this.filters.pickerFilters).length === 0 &&
        Object.keys(this.filters.optionSetFilters).length === 0 &&
        Object.keys(this.filters.booleanFilters).length === 0 &&
        query.length < 3 &&
        selectedColors.length === 0 &&
        !this.filterWindowOpened) {
        tempFilteredCards = this.cards;
      }
      tempFilteredCards = this.applyOrder(tempFilteredCards); // Apply order after filtering
      tempFilteredCards = this.populateWithAds(tempFilteredCards); // Re-populate with ads
      this.filteredCards = tempFilteredCards;
      this.state = 'Loaded'; // Trigger a state change to update the displayed cards
    },
    openModal(card, isDecklist) {
      const globalStore = useGlobalStore();
      try {
        if (isDecklist) {
          const deckbuilderStore = useDeckbuilderStore();
          this.modal_watchingList = deckbuilderStore.getDecklistDetails();
        } else {
          this.modal_watchingList = this.filteredCards;
        }

        // Ensure modal_watchingList is still an array before using findIndex
        if (!Array.isArray(this.modal_watchingList)) {
          return;
        }

        const cardIdColumn = this.gameConfig.cardId_column;
        const cardIndex = this.modal_watchingList.findIndex(c => c[cardIdColumn] === card[cardIdColumn]);

        this.modal_currentIndex = cardIndex;
        this.modal_selectedCard = this.modal_watchingList[cardIndex];
        this.showModalFlag = true;

        // Update URL query parameter
        /*const url = new URL(window.location.href);
        url.searchParams.set(cardIdColumn, card[cardIdColumn]);
        window.history.replaceState({}, '', url.toString());*/
      } catch (error) {
        globalStore.addToast({
          message: `Error opening modal: ${error.message}`,
          type: 'error',
        });
      }
    },
    resetCards() {
      this.cards = [];
    },
    restart() {
      this.init(this.gameConfig);
    },
    toggleDeckbuildingMode() {
      this.isDeckbuilding = !this.isDeckbuilding;
    },
    openFilterWindow() {
      this.filterWindowOpened = true;
      this.applyFilters();
    },
    updateCardSize(newSize) {
      this.cardSize = newSize;
      localStorage.setItem('cardSize', newSize);
      this.resetCardList();
    },
    setSelectedColors(colors) {
      this.selectedColors = colors;
      this.applyFilters();
    },
    setOrder(order) {
      this.order = order;
      this.filteredCards = this.applyOrder(this.filteredCards);
    },
    applyOrder(tempFilteredCards) {
      if (!tempFilteredCards) return tempFilteredCards;

      const orderKeys = Object.keys(this.order);
      const allUnset = orderKeys.every(key => this.order[key] === 'unset');

      if (!allUnset) {
        tempFilteredCards.sort((a, b) => {
          for (const key of orderKeys) {
            if (this.order[key] === 'unset') continue;
            const column = this.getColumnByKey(key);
            const direction = this.order[key] === 'ascending' ? 1 : -1;
            if (a[column] < b[column]) return -1 * direction;
            if (a[column] > b[column]) return 1 * direction;
          }
          return 0;
        });
      }

      // Create a map to group cards by their original ID
      const groupMap = new Map();
      const alternateVersions = new Map();

      // First pass: separate original cards and alternate versions
      tempFilteredCards.forEach(card => {
        const cardId = card[this.gameConfig.cardId_column];
        const originalId = card[this.gameConfig.cardOriginalId_column];
        
        if (originalId) {
          if (!alternateVersions.has(originalId)) {
            alternateVersions.set(originalId, []);
          }
          alternateVersions.get(originalId).push(card);
        } else {
          if (!groupMap.has(cardId)) {
            groupMap.set(cardId, card);
          }
        }
      });

      // Create the final ordered array
      const groupedCards = [];
      const processed = new Set();

      // Process cards in their original order, placing alternates right after their originals
      tempFilteredCards.forEach(card => {
        const cardId = card[this.gameConfig.cardId_column];
        
        if (processed.has(cardId)) return;
        
        // Add the original card first
        if (!card[this.gameConfig.cardOriginalId_column]) {
          groupedCards.push(card);
          processed.add(cardId);
          
          // Then add any alternate versions that exist for this card
          if (alternateVersions.has(cardId)) {
            alternateVersions.get(cardId).forEach(altCard => {
              if (!processed.has(altCard[this.gameConfig.cardId_column])) {
                groupedCards.push(altCard);
                processed.add(altCard[this.gameConfig.cardId_column]);
              }
            });
          }
        }
      });

      // Add any remaining alternate versions whose originals weren't in the filtered list
      tempFilteredCards.forEach(card => {
        const cardId = card[this.gameConfig.cardId_column];
        if (!processed.has(cardId)) {
          groupedCards.push(card);
          processed.add(cardId);
        }
      });

      // Place cards created within the last week at the beginning
      const oneWeekAgo = new Date();
      oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
      const recentCards = groupedCards.filter(card => new Date(card.created_at) >= oneWeekAgo);
      const otherCards = groupedCards.filter(card => new Date(card.created_at) < oneWeekAgo);

      return [...recentCards, ...otherCards];
    },
    getColumnByKey(key) {
      switch (key) {
        case 'id':
          return this.gameConfig.cardId_column;
        case 'cost':
          return this.gameConfig.cardCost_column;
        case 'name':
          return this.gameConfig.cardName_column;
        default:
          return '';
      }
    },
    resetStore() {
      this.$reset();
    },
    shouldAdTakeFullWidth() {
      return this.cardSize * 100 < 250;
    },
    resetCardList() {
      this.cards = [];
      // this.filteredCards = [];
      if (this.state !== 'LoadCards') {
        this.loadCards().then(() => {
        });
      }
    },
    hardResetCardList() {
      this.selectedColors = [];
      this.filters = {
        rangeFilters: {},
        singleRangeFilters: {},
        pickerFilters: {},
        optionSetFilters: {},
        booleanFilters: {}
      };
      this.order = {
        id: 'ascending',
        cost: 'unset',
        name: 'unset'
      };
      const seriesLoadedAtStart = this.gameConfig.seriesLoadedAtStart;
      if(seriesLoadedAtStart && seriesLoadedAtStart == '*') {
        this.resetCardList();
        return;
      }
      this.cards = [];
      this.filteredCards = [];
      this.state = 'SelectSeries';
      this.selectedSeries = [];
    },
    getFilteredStapleCards() {
      const globalStore = useGlobalStore();
      const stapleCardIds = globalStore.userStaples;
      return this.filteredCards.filter(card => stapleCardIds.includes(card[this.gameConfig.cardId_column]));
    },
    getFilterValue(filterName) {
      // First try to get from localStorage for saved filters
      const savedValue = localStorage.getItem(`${this.gameConfig.gameid}_filterValue_${filterName}`);
      if (savedValue !== null) {
        return JSON.parse(savedValue);
      }

      // Then check active filters
      for (const filterType in this.filters) {
        for (const [property, filter] of Object.entries(this.filters[filterType])) {
          if (property === filterName) {
            switch (filterType) {
              case 'optionSetFilters':
                return filter.selectedOptions;
              case 'pickerFilters':
                return filter.selectedItems;
              case 'rangeFilters':
                return { start: filter.start, end: filter.end };
              case 'singleRangeFilters':
                return filter.value;
              case 'booleanFilters':
                return true;
            }
          }
        }
      }
      return undefined;
    },
    async fetchCustomSeries() {
      try {
        const response = await fetch('https://exburst.dev/ua/external/series_functions.php?action=getPublicSeries', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          }
        });
        const data = await response.json();
        if (data.success) {
          this.customSeries = data.series;
          this.isCustomSeriesState = true;
        } else {
          console.error('Failed to fetch custom series:', data.message);
        }
      } catch (error) {
        console.error('Error fetching custom series:', error);
      }
    },
    selectCustomSeries(series,load = true) {
      this.customSeriesSelected = series;
      if(load) {
      this.updateURLQuery();
      this.state = 'LoadCards';
      this.loadCards();
      }
    },
    returnToStandardSeries() {
      this.isCustomSeriesState = false;
      this.customSeriesSelected = null;
      this.updateURLQuery();
      this.state = 'SelectSeries';
    },
    toggleSeriesCustomSelection(series) {
      if (this.customSeriesSelected && this.customSeriesSelected.series_map === series.series_map) {
        this.customSeriesSelected = null;
      } else {
        this.customSeriesSelected = series;
      }
      this.updateURLQuery();
      if (this.state !== 'PickMultipleSeries') {
        this.state = 'LoadCards';
        this.loadCards();
      }
    },
  }
});
