import { defineStore } from 'pinia';
import { useCardlistStore } from './cardlistStore';

export const useGlobalStore = defineStore('globalStore', {
  state: () => ({
    noScroll: false,
    fixedClasses: ['.deck-frame-container'],
    toasts: [],
    showLogin: false,
    previousRoute: null,
    userData: {}, // Initialize as an object
    userStapleCards: [],
    userStaples: [], // Initialize as an array
    isLoading: false,
    loadingMessage: '',
    userCheck: null, // New variable to track user check status
    gameConfig: null,
    ignoredEvents: [], // Add this new state for ignored events
  }),
  actions: {
    setNoScroll(value) {
      this.noScroll = value;
    },
    applyNoScroll() {
      try {
        const containers = document.querySelectorAll('.deck-frame-container');
        containers.forEach(container => {
          container.scrollTop = 0;
        });

        this.fixedClasses.forEach(className => {
          const elements = document.querySelectorAll(className);
          elements.forEach(element => {
            if (this.noScroll) {
              element.classList.add('no-scroll');
            } else {
              element.classList.remove('no-scroll');
            }
          });
        });
      } catch (error) {
        this.addToast({
          message: `Error applying no scroll: ${error.message}`,
          type: 'error',
        });
      }
    },
    removeNoScroll() {
      try {
        const noScrollElements = document.querySelectorAll('.no-scroll');
        noScrollElements.forEach(element => {
          element.classList.remove('no-scroll');
        });
      } catch (error) {
        this.addToast({
          message: `Error removing no scroll: ${error.message}`,
          type: 'error',
        });
      }
    },
    addToast(toast) {
      this.toasts.push(toast);
      setTimeout(() => {
        this.removeToast(toast);
      }, 4000);
    },
    removeToast(toast) {
      const index = this.toasts.indexOf(toast);
      if (index !== -1) {
        this.toasts.splice(index, 1);
      }
    },
    setShowLogin(value) {
      this.showLogin = value;
    },
    setPreviousRoute(route) {
      this.previousRoute = route;
    },
    updateUserData(data) {
      this.userData = data;
      this.userCheck = true; // Set userCheck to true when user data is updated
      this.userStaples = typeof data.userStapleCards === 'string' ? data.userStapleCards.split(';').map(s => s.trim()) : []; // Update userStaples
    },
    clearUserData() {
      this.userData = {};
      this.userCheck = false; // Set userCheck to false when user data is cleared
      this.userStaples = []; // Clear userStaples
      localStorage.removeItem('syncToken'); // Clear saved token
    },
    updateOwnedBanners(newBanners) {
      this.userData.ownedBanners = newBanners;
    },
    updateRoles(newRoles) {
      this.userData.roles = newRoles;
    },
    updateSyncToken(newToken) {
      this.userData.sync_token = newToken;
      localStorage.setItem('syncToken', newToken); // Save token locally
    },
    addStaple(staple) {
      if (Object.keys(this.userData).length === 0) {
        this.addToast({
          message: 'You must be logged in to mark staple.',
          type: 'error',
        });
        return;
      }

      if (this.userStaples == null) this.userStaples = [];
      if (!this.userStaples.includes(staple)) {
        this.userStaples.push(staple);
      }
      
      try {
        // Call backend to add staple
        const apiUrl = `${this.gameConfig.startUrl}/${this.gameConfig.gameid}/external/decklist-functions.php`;
        const requestBody = new URLSearchParams({
          action: 'setStapple', // Corrected action name
          cardId: staple,
          remove: false,
        });

        fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: requestBody,
        })
        .then(response => response.json())
        .then(result => {
          if (result.success) {
            this.addToast({
              message: 'Staple added successfully!',
              type: 'success',
            });
          } else {
            this.userStaples = this.userStaples.filter(s => s !== staple); // Revert the change
            this.addToast({
              message: result.message,
              type: 'error',
            });
          }
        })
        .catch(error => {
          this.userStaples = this.userStaples.filter(s => s !== staple); // Revert the change
          this.addToast({
            message: `Failed to save staple: ${error.message}`,
            type: 'error',
          });
        });
      } catch (error) {
        this.addToast({
          message: `Unexpected error: ${error.message}`,
          type: 'error',
        });
      }
    },
    removeStaple(staple) {
      if (Object.keys(this.userData).length === 0) {
        this.addToast({
          message: 'You must be logged in to mark staple.',
          type: 'error',
        });
        return;
      }

      if (this.userStaples == null) this.userStaples = [];
      this.userStaples = this.userStaples.filter(s => s !== staple);
      
      try {
        // Call backend to remove staple
        const apiUrl = `${this.gameConfig.startUrl}/${this.gameConfig.gameid}/external/decklist-functions.php`;
        const requestBody = new URLSearchParams({
          action: 'setStapple', // Corrected action name
          cardId: staple,
          remove: true,
        });

        fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: requestBody,
        })
        .then(response => response.json())
        .then(result => {
          if (result.success) {
            this.addToast({
              message: 'Staple removed successfully!',
              type: 'success',
            });
          } else {
            this.userStaples.push(staple); // Revert the change
            this.addToast({
              message: result.message,
              type: 'error',
            });
          }
        })
        .catch(error => {
          this.userStaples.push(staple); // Revert the change
          this.addToast({
            message: `Failed to remove staple: ${error.message}`,
            type: 'error',
          });
        });
      } catch (error) {
        this.addToast({
          message: `Unexpected error: ${error.message}`,
          type: 'error',
        });
      }
    },
    async claimBanner(item) {
      const globalStore = useGlobalStore();
      const url = `${this.gameConfig.startUrl}/${this.gameConfig.gameid}/external/manage_user_mails.php`;
      const data = {
        action: 'claimBanner',
        boxitem: item
      };
    
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data)
        });
    
        const result = await response.json();
        if (result.status === 'OK' || result.status === 'ERROR') { // Handle both OK and ERROR status
          // Show success/info message
          globalStore.addToast({
            message: result.message,
            type: result.status === 'OK' ? 'success' : 'info'
          });
    
          if (result.status === 'OK') {
            // Check if the banner is already in ownedBanners
            const ownedBanners = globalStore.userData.ownedBanners ? globalStore.userData.ownedBanners.split(",") : [];
            if (!ownedBanners.includes(item.banner)) {
              // Add the new banner to ownedBanners and update state
              ownedBanners.push(item.banner);
              globalStore.updateOwnedBanners(ownedBanners.join(","));
            }
          }
        }
      } catch (error) {
        console.error('Error claiming banner', error);
        globalStore.addToast({
          message: `Error claiming banner: ${error.message}`,
          type: 'error',
        });
      }
    },
    async claimRole(item) {
      const globalStore = useGlobalStore();
      const url = `${this.gameConfig.startUrl}/${this.gameConfig.gameid}/external/manage_user_mails.php`;
      const data = {
        action: 'claimRole',
        boxitem: item
      };
    
      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data)
        });
    
        const result = await response.json();
        if (result.status === 'OK' || result.status === 'ERROR') { // Handle both OK and ERROR status
          // Show message from backend
          globalStore.addToast({
            message: result.message,
            type: result.status === 'OK' ? 'success' : 'info'
          });
    
          if (result.status === 'OK') {
            // Check if the role is already in roles
            let roles = globalStore.userData.roles || '';
            const rolesArray = roles.split(',').map(role => role.trim());
            if (!rolesArray.includes(item.role)) {
              // Add the new role and update state
              rolesArray.push(item.role);
              globalStore.updateRoles(rolesArray.join(','));
            }
          }
        }
      } catch (error) {
        console.error('Error claiming role', error);
        globalStore.addToast({
          message: `Error claiming role: ${error.message}`,
          type: 'error',
        });
      }
    },
    trackEvent(eventName, params) {
      if (window.gtag) {
        window.gtag('event', eventName, {
          "event_category": "header-buttons",
          "event_label": params["event-label"],
          "value": params.value
        });
      }
    },
    setLoading({ isLoading, loadingMessage }) {
      this.isLoading = isLoading;
      this.loadingMessage = loadingMessage;
    },
    clearLoading() {
      this.isLoading = false;
      this.loadingMessage = '';
    },
    ignoreEvent(eventId) {
      if (!this.ignoredEvents.includes(eventId)) {
        this.ignoredEvents.push(eventId);
        // Save to localStorage
        localStorage.setItem('ignoredEvents', JSON.stringify(this.ignoredEvents));
      }
    },

    loadIgnoredEvents() {
      const saved = localStorage.getItem('ignoredEvents');
      if (saved) {
        this.ignoredEvents = JSON.parse(saved);
      }
    },

    clearIgnoredEvents() {
      this.ignoredEvents = [];
      localStorage.removeItem('ignoredEvents');
    }
  }
});
